header {
  width: 100%;
  background: $brand-red;
  .header-contents {
    width: 100%;
    float: left;
    height: 3.5em;
    .logo {
      position: relative;
      float: left;
      width: 2em;
      height: 2em;
      margin-right: 0.25em;
      top: 50%;
      transform: translateY(-50%);
    }
    .site-title {
      position: relative;
      float: left;
      padding: 1em 0;
      font-family: "Open Sans", sans-serif;
      text-decoration: none;
      color: $brand-white;
      top: 50%;
      transform: translateY(-50%);
    }
    .linkgroup {
      float: right;
      margin-right: -1.5em;
      a {
        float: left;
        padding: 1em 1.5em;
        color: rgba($brand-white, 0.75);
        text-decoration: none;
        transition: ease all 0.25s;
        &:hover {
          color: $brand-white;
          background: rgba($brand-black, 0.1);
        }
      }
    }
    .header-search {
      position: relative;
      border: none;
      float: right;
      max-width: 20em;
      min-width: 10em;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      input {
        color: $brand-white;
        width: 100%;
        float: left;
        border: none;
        padding: 0.5em;
        outline: none;
        background: fade($brand-black, 20%);
        transition: ease all 0.25s;
        .placeholder {
          color: fade($brand-white, 50%);
        }
        &:active,
        &:focus {
          background: fade($brand-white, 100%);
          .placeholder {
            color: fade($brand-black, 50%);
          }
          color: $brand-black;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  header {
    text-align: center;
  }
  .home_link {
    float: none !important;
    display: inline-block;
  }
  .home_link {
    margin-left: -0.75em;
  }
}

#nav-buttons {
  margin-top: 30px;
  button.nav-button {
    box-sizing: border-box;
    background-color: $brand-white !important;
    border: none;
    padding: 0px;
    margin: 0px;

    .circle-icon {
      background-color: $brand-white !important;
      color: $active-color;
      height: 50px;
      width: 50px;
      border-radius: 150px;
      font-size: 30px;
      border: 2px solid $active-color;
      .fa-angle-left {
        position: relative;
        top: 1px;
        left: -2px;
      }
      .fa-angle-right {
        position: relative;
        top: 2px;
        left: 1px;
      }
      .fa.fa-check {
        font-size: 22px;
        position: relative;
        top: 0px;
        left: 0px;
      }
      .fa.fa-thumbs-o-up {
        font-size: 22px;
        position: relative;
        top: 0px;
        left: 0px;
      }
    }
    span.button-text {
      color: $active-color;
      font-size: 20px;
      font-weight: 300 !important;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      position: relative;
      display: inline-block;
      margin: 11px;
    }
    &:hover {
      outline: none !important;
      .circle-icon {
        color: lighten($active-color, 10%);
        border: 2px solid lighten($active-color, 10%);
      }
      span.button-text {
        color: lighten($active-color, 10%);
      }
    }
    &:focus {
      outline: 1px solid lighten($active-color, 10%);
      outline-offset: 4px;
      .circle-icon {
        color: lighten($active-color, 10%);
        border: 2px solid lighten($active-color, 10%);
      }
      span.button-text {
        color: lighten($active-color, 10%);
      }
    }
  }

  button#previous,
  button#start-over,
  button#cancel-contact-form {
    .circle-icon,
    span.button-text {
      color: $brand-slate;
    }
    .circle-icon {
      border-color: $brand-slate;
    }
  }
}
