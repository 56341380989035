// App Default
$brand-color: rgb(228, 37, 42);
$brand-alt-color: rgb(229, 72, 45);

// Brand Colors
$brand-red: rgb(228, 37, 42);
$brand-alt-red: rgb(229, 72, 45);

$brand-green: rgb(0, 194, 82);
$brand-alt-green: rgb(10, 214, 102);

$brand-purple: rgb(118, 34, 255);
$brand-alt-purple: rgb(138, 65, 255);

$brand-blue: rgb(21, 180, 219);
$brand-alt-blue: rgb(21, 126, 249);
$brand-link-blue: rgb(27, 118, 224);

$brand-black: rgb(5, 10, 20);
$brand-slate-dark: rgb(59, 65, 80);
$brand-slate: rgb(61, 68, 80);
$brand-slate-light: #dfe4ea;
$brand-slate-lighter: #f5f7f9;
$brand-white: #ffffff;

// Utility Colors
$action-color: $brand-blue;
$draft-color: rgb(255, 132, 0);
$attention-color: $brand-red;
$active-color: rgb(32, 171, 156);
$notes-color: rgb(250, 201, 64);
$comments-color: $brand-blue;
$placeholder-color: tint($brand-slate, 30%);

// Graph Colors
$graph-hex-purple-dark: #2e18a5;
$graph-hex-purple: #6945d8;
$graph-hex-purple-light: #9f73ff;

$graph-hex-orange-dark: #c42800;
$graph-hex-orange: #ff5e00;
$graph-hex-orange-light: #ff903e;

$graph-hex-yellow-dark: #ee9d01;
$graph-hex-yellow: #ffbb00;
$graph-hex-yellow-light: #ffce46;

$graph-hex-green-dark: #006400;
$graph-hex-green: #00932d;
$graph-hex-green-light: #50c55b;

$graph-hex-teal-dark: #00a998;
$graph-hex-teal: #33dbc9;
$graph-hex-teal-light: #76fffc;

$graph-hex-blue-dark: #005c74;
$graph-hex-blue: #1889a3;
$graph-hex-blue-light: #5ab9d4;

$graph-purple-dark: rgb(46, 24, 165);
$graph-purple: rgb(105, 69, 216);
$graph-purple-light: rgb(159, 115, 255);

$graph-orange-dark: rgb(196, 40, 0);
$graph-orange: rgb(255, 94, 0);
$graph-orange-light: rgb(255, 144, 62);

$graph-yellow-dark: rgb(238, 157, 1);
$graph-yellow: rgb(255, 187, 0);
$graph-yellow-light: rgb(255, 206, 70);

$graph-green-dark: rgb(0, 100, 0);
$graph-green: rgb(0, 147, 45);
$graph-green-light: rgb(80, 197, 91);

$graph-teal-dark: rgb(0, 169, 152);
$graph-teal: rgb(51, 219, 201);
$graph-teal-light: rgb(118, 255, 252);

$graph-blue-dark: rgb(0, 92, 116);
$graph-blue: rgb(24, 137, 163);
$graph-blue-light: rgb(90, 185, 212);

// Graph Colors by Priority
$bar-graph-1-primary: $graph-purple;
$bar-graph-1-secondary: $graph-purple-light;
$bar-graph-2-primary: $graph-teal;
$bar-graph-2-secondary: $graph-teal-light;
$bar-graph-3-primary: $graph-yellow;
$bar-graph-3-secondary: $graph-yellow-light;
$bar-graph-4-primary: $graph-blue;
$bar-graph-4-secondary: $graph-blue-light;
$bar-graph-5-primary: $graph-green;
$bar-graph-5-secondary: $graph-green-light;

$gender-graph-primary-male: $graph-purple;
$gender-graph-secondary-male: $graph-purple-light;
$gender-graph-primary-female: $graph-teal;
$gender-graph-secondary-female: $graph-teal-light;
$gender-graph-primary-non-binary: $graph-yellow;
$gender-graph-secondary-non-binary: $graph-yellow-light;
$gender-graph-primary-not-entered: $graph-blue;
$gender-graph-secondary-not-entered: $graph-blue-light;

$circle-graph-1: $graph-teal;
$circle-graph-2: $graph-yellow;
$circle-graph-3: $graph-purple;
$circle-graph-4: $graph-teal-light;
$circle-graph-5: $graph-orange-light;
$circle-graph-6: $graph-purple-light;
$circle-graph-7: $graph-blue;

// Helper Classes
.color-red {
  color: $brand-red !important;
}

.color-alt-red {
  color: $brand-alt-red !important;
}

.color-green {
  color: $brand-green !important;
}

.color-alt-green {
  color: $brand-alt-green !important;
}

.color-blue {
  color: $brand-blue !important;
}

.color-alt-blue {
  color: $brand-alt-blue !important;
}

.color-purple {
  color: $brand-purple !important;
}

.color-black {
  color: $brand-black !important;
}

.color-slate {
  color: $brand-slate !important;
}

.color-slate-light {
  color: tint($brand-slate, 30%) !important;
}

.color-slate-lighter {
  color: tint($brand-slate, 70%) !important;
}

.color-slate-lightest {
  color: $brand-slate-light !important;
}

.color-white {
  color: $brand-white !important;
}

.color-primary {
  color: $brand-color !important;
}

.color-active {
  color: $active-color !important;
}

.color-graph-purple {
  color: $graph-purple !important;
}

.color-graph-purple-light {
  color: $graph-purple-light !important;
}

.color-graph-orange {
  color: $graph-orange !important;
}

.color-graph-orange-light {
  color: $graph-orange-light !important;
}

.color-graph-yellow {
  color: $graph-yellow !important;
}

.color-graph-yellow-light {
  color: $graph-yellow-light !important;
}

.color-graph-green {
  color: $graph-green !important;
}

.color-graph-green-light {
  color: $graph-green-light !important;
}

.color-graph-teal {
  color: $graph-teal !important;
}

.color-graph-teal-light {
  color: $graph-teal-light !important;
}

.color-graph-blue {
  color: $graph-blue !important;
}

.color-graph-blue-light {
  color: $graph-blue-light !important;
}

.bg-red {
  background-color: $brand-red !important;
}

.bg-alt-red {
  background-color: $brand-alt-red !important;
}

.bg-green {
  background-color: $brand-green !important;
}

.bg-alt-green {
  background-color: $brand-alt-green !important;
}

.bg-blue {
  background-color: $brand-blue !important;
}

.bg-alt-blue {
  background-color: $brand-alt-blue !important;
}

.bg-black {
  background-color: $brand-black !important;
}

.bg-slate-dark {
  background-color: $brand-slate !important;
}

.bg-slate {
  background-color: $brand-slate !important;
}

.bg-slate-light {
  background-color: tint($brand-slate, 30%) !important;
}

.bg-slate-lighter {
  background-color: tint($brand-slate, 70%) !important;
}

.bg-slate-lightest {
  background-color: $brand-slate-light !important;
}

.bg-white {
  background-color: $brand-white !important;
}

.bg-primary {
  background-color: $brand-color !important;
}

.bg-graph-purple {
  background-color: $graph-purple !important;
}

.bg-graph-purple-light {
  background-color: $graph-purple-light !important;
}

.bg-orange {
  background-color: $graph-orange !important;
}

.bg-graph-orange-light {
  background-color: $graph-orange-light !important;
}

.bg-graph-yellow {
  background-color: $graph-yellow !important;
}

.bg-graph-yellow-light {
  background-color: $graph-yellow-light !important;
}

.bg-graph-green {
  background-color: $graph-green !important;
}

.bg-graph-green-light {
  background-color: $graph-green-light !important;
}

.bg-graph-teal {
  background-color: $graph-teal !important;
}

.bg-graph-teal-light {
  background-color: $graph-teal-light !important;
}

.bg-graph-blue {
  background-color: $graph-blue !important;
}

.bg-graph-blue-light {
  background-color: $graph-blue-light !important;
}

.brand-gradient {
  background: $brand-color;
  background: linear-gradient(
    to right,
    $brand-color 0%,
    $brand-alt-color 100%
  ) !important;
}

.content-needs-attention {
  color: $brand-red !important;
}

.content-muted {
  color: tint($brand-black, 70%) !important;
}

.content-active {
  color: $active-color !important;
}

.content-drafted {
  color: $draft-color !important;
}

.grey-opacity {
  color: grey;
  opacity: 0.5;
}

// External Brand Colors
$ms-excel-color: rgb(33, 115, 70);
$ms-word-color: rgb(43, 87, 154);
$adobe-pdf-color: rgb(212, 33, 27);
