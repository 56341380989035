@import "https://fonts.googleapis.com/css?family=Shadows+Into+Light+Two";
@import "https://use.typekit.net/dhh4vdw.css";

// Set all to open sans by default
* {
  font-family: "open-sans", sans-serif;
}

$base-font-size: 14px;

$proxima-soft: "proxima-soft", sans-serif;
$open-sans: "open-sans", sans-serif;
$font-awesome: "Font Awesome 5 Pro";
$font-awesome-brands: "Font Awesome 5 Brands";
$shadows-into-light: "Shadows Into Light Two", cursive;

$weight-100: 100;
$weight-200: 200;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $proxima-soft;
  font-weight: $weight-300;
  color: inherit;
  margin: 0 0 1rem;
  line-height: 1;
}

h1,
.h1 {
  & * {
    font-family: $proxima-soft;
    font-weight: $weight-100;
  }
  font-family: $proxima-soft;
  font-weight: $weight-100;
  font-size: 2em;
  width: 100%;
  float: left;
  margin-bottom: 0.25em;
  line-height: 1.125;
}
h2,
.h2 {
  & * {
    font-family: $proxima-soft;
    font-weight: $weight-300;
  }
  font-size: 1.5em;
  font-family: $proxima-soft;
  font-weight: $weight-300;
  line-height: 1.25;
  small {
    padding-left: 1em;
    font-size: 0.875rem;
  }
}
h3,
.h3 {
  & * {
    font-family: $proxima-soft;
    font-weight: $weight-300;
  }
  font-size: 1.35em;
  margin-bottom: 0.5em;
}
h4,
.h4 {
  & * {
    font-family: $proxima-soft;
    font-weight: $weight-400;
  }
  font-family: $proxima-soft;
  font-weight: $weight-400;
  font-size: 1em;
}

// Type display classes
.display-1 {
  & * {
    font-family: $proxima-soft;
    font-weight: $weight-300;
  }
  font-family: $proxima-soft;
  font-weight: $weight-300;
  color: inherit;
  font-size: 2.25em;
}
.display-2 {
  & * {
    font-family: $proxima-soft $weight-300;
  }
  font-family: $proxima-soft;
  font-weight: $weight-300;
  color: inherit;
  font-size: 3em;
}
.display-3 {
  & * {
    font-family: $proxima-soft;
    font-weight: $weight-300;
  }
  font-family: $proxima-soft;
  font-weight: $weight-300;
  color: inherit;
  font-size: 3.75em;
}
.display-big {
  font-family: $proxima-soft;
  font-weight: $weight-300;
  & * {
    font-family: $proxima-soft;
    font-weight: $weight-300;
  }
  color: inherit;
  font-size: 4.5em;
  line-height: 0.875;
  display: inline-block;
  margin: 0;
  letter-spacing: -2px;
}

strong,
span,
p,
.default-text {
  font-size: 1em;
  color: inherit;
  margin: 0;
  margin-bottom: 0.5em;
}
p {
  margin-bottom: 1em;
  line-height: 1.5;
  a {
    color: $brand-link-blue;
  }
}

label,
.label {
  font-family: $open-sans;
  font-weight: $weight-700;
  font-size: 0.875em;

  margin-bottom: 0.25em;
  color: inherit;
}

small,
.small {
  font-size: 0.875em;
  margin-bottom: 1em;
  color: inherit;
}

strong {
  font-weight: 600;
}

.nobr {
  white-space: nowrap;
  text-overflow: ellipsis;
}
