@mixin transition-all($time) {
  -webkit-transition: all $time;
  -moz-transition: all $time;
  -ms-transition: all $time;
  -ms-transition: all $time;
  transition: all $time;
}

@mixin BoxShadowHelper($level: 1) {
  @if $level == -1 {
    box-shadow: inset 0 3px 6px rgba($brand-black, 0.1);
  }

  @if $level == 1 {
    box-shadow: 0 1px 3px rgba($brand-black, 0.05),
      0 1px 2px rgba($brand-black, 0.1);
  }

  @if $level == 2 {
    box-shadow: 0 3px 6px rgba($brand-black, 0.05),
      0 3px 6px rgba($brand-black, 0.1);
  }

  @if $level == 3 {
    box-shadow: 0 10px 20px rgba($brand-black, 0.05),
      0 6px 6px rgba($brand-black, 0.1);
  }

  @if $level == 4 {
    box-shadow: 0 14px 28px rgba($brand-black, 0.05),
      0 10px 10px rgba($brand-black, 0.1);
  }

  @if $level == 5 {
    box-shadow: 0 19px 38px rgba($brand-black, 0.05),
      0 15px 12px rgba($brand-black, 0.1);
  }
}

.brand-gradient {
  background: $brand-color;
  background: linear-gradient(
    to right,
    $brand-color 0%,
    $brand-alt-color 100%
  ) !important;
}

label,
.label {
  font-family: $open-sans;
  font-weight: $weight-700;
  font-size: 0.875em;

  margin-bottom: 0.25em;
  color: inherit;
}

h1 {
  font-size: 2em;
  width: 100%;
  font-weight: 100;
}

h2 {
  font-size: 30px;
  font-family: proxima-soft, sans-serif;
  font-weight: 300;
}

.select {
  width: 100%;
  position: relative;
  border: 1px solid #dfe4ea;
  background-color: #fff;
  padding: 2px 8px;
  min-height: 40px;
}

.wizard-body,
.wizard-review-object {
  display: flex;
  flex-direction: column;
  button:not(.SingleDatePickerInput_calendarIcon) {
    border-radius: 2px;
    padding: 0.65em 1.5em;
    box-shadow: 0 0 0 rgba($brand-black, 0);
    color: $brand-white;
    background: $brand-color;
    border: 1px solid $brand-color;
    min-width: 5em;
    @include transition-all(0.25s);
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 0.125em 0.25em rgba($brand-black, 0.2);
      color: $brand-white;
      background: $brand-alt-color;
    }
    &.secondary {
      color: rgba($brand-slate, 0.7);
      background: none;
      border: 1px solid rgba($brand-slate, 0.7);
    }
    &:disabled,
    &.disabled {
      color: rgba($brand-white, 0.5) !important;
      background: tint($brand-slate, 70%) !important;
      border: 1px solid rgba($brand-slate, 0) !important;
      cursor: not-allowed;
      &:hover {
        transform: none;
        box-shadow: none;
      }
    }
    &.fab {
      background: none;
      border: 1px solid rgba($brand-slate, 0.05);
      color: $brand-slate;
      border-radius: 2em;
      padding: 0;
      line-height: 2em;
      width: 3em;
      height: 3em;
      text-align: center;
      &:hover {
        color: $brand-red;
      }
    }
  }
}

.wizard-contents {
  flex: 1;
  input,
  textarea,
  .select__control {
    background: $brand-white;
    border: 1px solid $brand-slate-light;
  }
  .wizard-title-section {
    padding: 4em 0;
  }
  .document-tabs {
    &:before {
      left: 0;
      background: linear-gradient(
        to right,
        rgba($brand-white, 0.7) 0%,
        rgba($brand-white, 0.7) 100%
      ) !important;
    }
    &:after {
      right: 0;
      background: linear-gradient(
        to left,
        rgba($brand-white, 0.7) 0%,
        rgba($brand-white, 0.7) 100%
      ) !important;
    }

    .tab {
      box-shadow: none !important;
      .content {
        background: $brand-slate-light;
        background: linear-gradient(
          180deg,
          $brand-slate-light 80%,
          $brand-slate-light 100%
        );
        box-shadow: none !important;
      }
      &.active {
        .content {
          background: tint($brand-slate-light, 80%) !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .empty-state {
    margin: 0 0 3em;
    padding: 3em 0;
    background: tint($brand-slate-light, 80%) !important;
    box-shadow: none;
    float: left;
    width: 100%;
  }
}
.wizard-contents-inner {
  max-width: 120em;
  margin: 0 auto;
}

.wizard-timeline {
  flex: none;
  float: left;
  width: 94%;
  margin: 0 3%;
  height: 3.5em;
  padding: 2.25em 1em 0;
}

.segment-container {
  width: 100%;
  height: 0.715em;
  border-radius: 5em;
  background: $brand-slate-light;
  float: left;
  position: relative;
  display: flex;

  .segment {
    height: 100%;
    float: left;
    position: relative;
    flex-grow: 1;
    min-width: 10%;
    padding: 0.125em 0.25em;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -0.625em;
      width: 1.25em;
      height: 1.25em;
      top: -0.25em;
      z-index: 0;
      border-radius: 50%;
      background: $brand-slate-light;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: -0.375em;
      width: 0.75em;
      height: 0.75em;
      z-index: 1;
      border-radius: 5em;
      background: $brand-color;
      opacity: 0;
      transform: scale(0.2, 0.2);
      @include transition-all(0.25s);
    }
    .segment-label {
      font-size: 0.75rem;
      font-weight: 600;
      position: absolute;
      top: -3em;
      left: 0;
      width: 100%;
      text-align: center;
      margin-left: -50%;
      @include transition-all(0.25s);
      color: $brand-slate;
    }
    .progress {
      top: 25%;
      height: 50%;
      width: 0;
      border-radius: 5em;
      position: relative;
      background: $brand-color;
      @include transition-all(0.25s);
      z-index: 1;
    }

    &.segment-active {
      color: $brand-color;
      .segment-label {
        color: rgba($brand-color, 0.5);
      }
      &:after {
        opacity: 1;
        transform: scale(1, 1);
      }
    }

    &.segment-current {
      .segment-label {
        color: $brand-color;
        &:after {
          top: 120%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba($brand-green, 0);
          border-top-color: $brand-green;
          border-width: 0.5em;
          margin-left: -0.5em;
        }
      }
    }

    &:first-of-type {
      &:before {
        left: 0;
      }

      &:after {
        left: 0.25em;
      }
      .segment-label {
        left: 0;
        margin-left: 0.7em;
        width: auto;
        transform: translateX(-50%);
      }
    }

    &:last-of-type {
      position: absolute;
      width: 100%;
      .segment-label {
        right: 0;
        margin-right: 0.7em;
        width: auto;
        transform: translateX(50%);
      }
      .progress {
        opacity: 0;
      }
      &:before {
        right: 0;
        left: auto;
      }
      &:after {
        right: 0;
        left: auto;
        margin-right: 0.25em;
        opacity: 0;
      }
    }
  }
}

.wizard-form-container {
  position: relative;
  background: tint($brand-slate-light, 80%);
  border: 1px solid rgba($brand-slate-light, 0.05);
  padding: 0.5em !important;
  margin-bottom: 2em;

  & > .row {
    padding: 1.5em;
  }
  & > .row:nth-of-type(even) {
    background: rgba($brand-slate-light, 0.03);
  }

  &.with-errors {
    border: 1px solid $brand-red;
    h1 {
      color: $brand-red;
    }
    .error {
      position: absolute;
      top: 0;
      right: 0;
      padding: 2.875em 2em;
      color: $brand-red;
      font-size: 0.875em;
    }
  }
  &.with-errors {
    border: 1px solid $brand-red;
    padding-top: 3em !important;
    h2 {
      color: $brand-red !important;
    }
    .error {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      color: $brand-white;
      background: $brand-red;
      border: 1px solid $brand-red;
      font-size: 0.875em;
      margin: 0;
      text-align: center;
    }
  }

  &.with-warning {
    border: 1px solid $draft-color;
    padding-top: 3em !important;
    h2 {
      color: $draft-color !important;
    }
    .warning-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      color: $brand-white;
      background: $draft-color;
      font-size: 0.875em;
      margin: 0;
      text-align: center;
    }
  }

  &.is-disabled {
    border: 1px solid $brand-slate-light;
    padding-top: 3em !important;
    transform: none;
    box-shadow: none;

    .expandable-header {
      cursor: not-allowed;
      h2,
      p {
        color: rgba($brand-slate, 0.3);
      }
      h2:after,
      h2:before {
        display: none;
      }
    }
    .disabled-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      color: $brand-slate;
      background: $brand-slate-light;
      border: 1px solid $brand-slate-light;
      font-size: 0.875em;
      margin: 0;
      text-align: center;
    }
  }
}

.expandable {
  padding: 0 0 0 3em;
  position: relative;
  float: left;
  width: 100%;
  border: 1px solid rgba($brand-slate-light, 0);
  background: tint($brand-slate-light, 80%);
  margin-bottom: 2em;

  @include transition-all(0.25s);
  .expandable-header {
    padding: 2em;
    float: left;
    width: 100%;
    position: relative;
    cursor: pointer;
    h2 {
      margin: 0.25em 0;
      color: $brand-slate;
      position: relative;
      width: 100%;
      float: left;
      padding-right: 5.5em;
      position: relative;
      &.expandable-title-small {
        font-size: 1em;
        padding-top: 0.65em;
        width: 60%;
      }
      &:before,
      &:after {
        position: absolute;
        content: "";
        left: -2.125em;
        top: 50%;
        margin-top: -5px;
        border-radius: 5em;
        @include transition-all(0.25s);
      }
      &:before {
        z-index: 1;
        width: 15px;
        height: 15px;
        margin-left: -1px;
        margin-top: -6px;
        background: rgba($brand-slate, 0.3);
      }
      &:after {
        z-index: 1;
        width: 13px;
        height: 13px;
        background: $brand-slate-light;
        border: 0 solid $brand-white;
      }
      .recommended {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 500;
        font-size: 0.6em;
        margin-top: 0.125em;
        color: $brand-green;
      }
    }
    p {
      color: $brand-slate;
      float: left;
      max-width: 45em;
      margin: 0.5em 0;
    }
  }
  .expandable-contents {
    float: left;
    width: 100%;
    padding: 0;
    transition: ease all 0.25;
    height: 0;
    overflow: hidden;
    .expandable-question {
      float: left;
      width: 100%;
      .radios {
        margin: 0.25em 0 0.5em 0;
        .radio {
          float: none;
          display: block;
        }
        .percent-input,
        .number-input,
        textarea,
        input[type="text"] {
          width: 70%;
          position: relative;
          margin: 0.75em 0 1em 1.5em;
          background: $brand-white;
          border: 1px solid $brand-slate-light;
        }
        .percent-input {
          width: 7em;
        }
      }
      .table-section {
        padding-right: 3em;
        &:before {
          left: -3em !important;
          background: linear-gradient(
            to right,
            tint($brand-slate-light, 80%) 0%,
            rgba($brand-slate-light, 0) 100%
          ) !important;
        }
        &:after {
          right: 0 !important;
          background: linear-gradient(
            to left,
            tint($brand-slate-light, 80%) 0%,
            rgba($brand-slate-light, 0) 100%
          ) !important;
        }
      }
    }
  }

  &:hover {
    transform: translateY(-1px);
    background: tint($brand-slate-light, 90%);
    border: 1px solid $brand-slate-light;
    @include BoxShadowHelper(2);
    .expandable-header {
      h2 {
        &:after {
          background: rgba($brand-slate, 0.3);
        }
      }
    }
    .table-section {
      &:before {
        background: linear-gradient(
          to right,
          tint($brand-slate-light, 90%) 0%,
          rgba($brand-slate-light, 0) 100%
        ) !important;
      }
      &:after {
        background: linear-gradient(
          to left,
          tint($brand-slate-light, 90%) 0%,
          rgba($brand-slate-light, 0) 100%
        ) !important;
      }
    }
  }

  &.expandable-expanded {
    transform: none;
    box-shadow: none;
    .expandable-header {
      h2 {
        color: $brand-slate;
        &:after {
          background: rgba($brand-slate, 0.7);
          border: 2px solid $brand-slate-light;
        }
      }
      p {
        color: $brand-slate;
      }
    }

    .expandable-contents {
      height: auto;
      overflow: visible !important;
    }
  }
  &.with-errors {
    border: 1px solid $brand-red;
    padding-top: 4em;
    h2 {
      color: $brand-red !important;
    }
    .error {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      color: $brand-white;
      background: $brand-red;
      border: 1px solid $brand-red;
      font-size: 0.875em;
      margin: 0;
      text-align: center;
    }
  }

  &.with-warning {
    border: 1px solid $draft-color;
    padding-top: 4em;
    h2 {
      color: $draft-color !important;
    }
    .warning-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      color: $brand-white;
      background: $draft-color;
      font-size: 0.875em;
      margin: 0;
      text-align: center;
    }
  }

  &.is-disabled {
    border: 1px solid $brand-slate-light;
    padding-top: 4em;
    transform: none;
    box-shadow: none;

    .expandable-header {
      cursor: not-allowed;
      h2,
      p {
        color: rgba($brand-slate, 0.3);
      }
      h2:after,
      h2:before {
        display: none;
      }
    }
    .disabled-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      color: $brand-slate;
      background: $brand-slate-light;
      border: 1px solid $brand-slate-light;
      font-size: 0.875em;
      margin: 0;
      text-align: center;
    }
  }

  &.for-results {
    border: 1px solid $brand-blue;
    padding-top: 4em;
    h2 {
      color: $brand-blue !important;
    }
    .results-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      color: $brand-white;
      background: $brand-blue;
      border: 1px solid $brand-blue;
      font-size: 0.875em;
      margin: 0;
      text-align: center;
    }
  }
}

.wizard-sidebar {
  @include transition-all(0.0001s);
  background: tint($brand-slate-light, 80%);
  border: 1px solid rgba($brand-slate-light, 0.05);

  &:before,
  &:after {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2em;
    z-index: 2;
    content: "";
  }
  &:before {
    background: linear-gradient(
      to bottom,
      rgba($brand-slate-lighter, 1) 0%,
      rgba($brand-slate-lighter, 0) 100%
    );
    top: 0;
    left: 0;
  }
  &:after {
    content: "";
    background: linear-gradient(
      to top,
      rgba($brand-slate-lighter, 1) 0%,
      rgba($brand-slate-lighter, 0) 100%
    );
    top: auto;
    bottom: 0;
  }
  input {
    background: $brand-white;
    border: 1px solid $brand-slate-light;
    &:hover,
    &:focus {
      background: $brand-white;
    }
  }
  .sidebar-clear-filters {
    color: tint($brand-slate, 30%);
    font-weight: 400;
    float: right;
    @include transition-all(0.25s);
    cursor: pointer;
    &:hover {
      color: $brand-green;
    }
  }
  .wizard-sidebar-buttons {
    margin-bottom: 2em;
    &:last-of-type {
      margin-bottom: 0;
    }
    button {
      display: flex;
      align-items: center;
      @include transition-all(0.25s);
      font-weight: $weight-400;
      line-height: 1.25;
      border: 1px solid rgba($brand-slate-lighter, 0.05);
      background-color: $brand-white;
      color: tint($brand-slate, 30%);
      font-size: 0.875em;
      padding: 1em 0.5em 1em 1em;
      width: 100%;
      border-radius: 0;
      text-align: left;
      box-shadow: 0 0 0 rgba($brand-black, 0);
      margin-top: 1em;
      &:hover {
        color: $brand-slate;
        background-color: $brand-white;
        border: 1px solid $brand-slate-light;
        @include BoxShadowHelper(1);
        transform: translateY(-2px);
      }
      &:active {
        transform: scale(0.95);
      }
      i {
        margin-top: 2px;
        padding-right: 1em;
        float: left;
      }
      span {
        float: left;
        margin: 0;
        max-width: 70%;
        line-height: 1.25;
      }
    }
  }

  .wizard-sidebar-anchors {
    margin: 1em 0 2em;
    list-style-type: none;
    float: left;
    width: 100%;
    a {
      counter-increment: step-counter;
      margin-bottom: 0.25em;
      color: $brand-slate;
      width: 100%;
      float: left;
      color: $brand-color;
      text-decoration: none;
      cursor: pointer;
      @include transition-all(0.25s);
      span {
        float: right;
        font-family: $open-sans;
        font-weight: $weight-600;
        color: $brand-slate;
      }
      &:hover {
        color: $brand-alt-color;
      }
      &:active {
        transform: scale(0.95);
      }
      &.has-errors {
        color: $brand-red;
      }
    }
  }

  &.wizard-sidebar-has-grade {
    .wizard-sidebar-scrollable {
      padding-bottom: 12em !important;
    }
  }

  .grade-circle,
  .grade-box {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.25em 1em 2em;
    width: 100%;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgba($brand-slate-lighter, 0.9) 0%,
      rgba($brand-slate-lighter, 1) 100%
    );
    position: absolute;
    bottom: -1px;
    pointer-events: none;
    .grade-value {
      font-family: $proxima-soft;
      font-weight: $weight-200;
      margin-bottom: 0.25em;
      font-size: 2.25em;
      color: $brand-color;
      border: 1px solid $brand-color;
      border-radius: 100%;
      width: 3.5em;
      height: 3.5em;
      line-height: 3.4em;
      display: inline-block;
      sup {
        font-family: $proxima-soft;
        font-weight: $weight-200;
        top: -0.45em;
        font-size: 0.6em;
      }
    }
    .grade-label {
      font-size: 1em;
      display: block;
      color: $brand-slate;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:before {
      background: linear-gradient(
        to bottom,
        rgba($brand-slate-lighter, 0) 0%,
        rgba($brand-slate-lighter, 0.9) 100%
      );
      left: 0;
      content: "";
      top: -2em;
      z-index: 2;
      height: 2em;
      width: 100%;
      position: absolute;
    }

    &.circle-error {
      .grade-value {
        color: $brand-red;
        border: 1px solid $brand-red;
      }
    }

    &.circle-warning {
      .grade-value {
        color: $draft-color;
        border: 1px solid $draft-color;
      }
    }
  }

  .select__set {
    position: relative;
    .select__control {
      background: tint($brand-green, 90%);
      @include transition-all(0.25s);
      * {
        color: $brand-slate;
      }
    }
  }
}

.wizard-form-container {
  background: tint($brand-slate-light, 80%);
  padding: 2em;
  &.with-errors {
    h1 {
      color: $brand-red;
    }
  }
}

.wizard-sidebar {
  background: tint($brand-slate-light, 80%);
  input {
    background: $brand-white;
    border: 1px solid $brand-slate-light;
    &:hover,
    &:focus {
      background: $brand-white;
    }
  }
  .wizard-sidebar-buttons {
    margin-bottom: 2em;
    button {
      @include transition-all(0.25s);
      font-family: $proxima-soft;
      font-weight: $weight-400;
      line-height: 1.25;
      border: 1px solid rgba($brand-slate, 0.5);
      background-color: $brand-white;
      color: tint($brand-slate, 30%);
      font-size: 0.875em;
      padding: 1em;
      width: 100%;
      border-radius: 0;
      text-align: left;
      box-shadow: 0 0 0 rgba($brand-black, 0);
      margin-top: 1em;
      &:hover {
        color: $brand-slate;
        border: 1px solid $brand-slate-light;
        background: rgba($brand-slate-light, 0.3);
        @include BoxShadowHelper(1);
        transform: translateY(-2px);
      }
      i {
        padding-right: 1em;
      }
    }
  }

  .wizard-sidebar-anchors {
    margin: 1em 0 2em;
    list-style-type: none;
    float: left;
    width: 100%;
    a {
      counter-increment: step-counter;
      margin-bottom: 1em;
      color: $brand-slate;
      width: 100%;
      float: left;
      color: $brand-color;
      text-decoration: none;
      @include transition-all(0.25s);
      span {
        float: right;
        font-family: $open-sans;
        font-weight: $weight-600;
        color: $brand-slate;
      }
      &:hover {
        color: $brand-alt-color;
      }
    }
    .has-errors {
      color: $brand-red;
    }
  }
}

.wizard-footer {
  flex: none;
  background-color: $brand-slate-light;
  width: 100%;
  padding: 2em;
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  button {
    margin-left: 1em;
    &:active {
      transform: scale(0.95);
    }
  }
  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 2em;
    background: linear-gradient(
      to top,
      rgba($brand-white, 1) 0%,
      rgba($brand-white, 0) 100%
    );
  }
  .exit-link {
    text-decoration: none;
    color: rgba($brand-slate, 0.7);
    @include transition-all(0.25s);
    font-size: 1em;
    padding: 0.75em 0;
    display: inline-block;
    .fa {
      padding-right: 0.5em;
    }
    &:hover {
      color: $brand-red;
    }
  }
  .wizard-has-errors {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    padding: 1.25em;
    text-align: center;
    background: $brand-red;
    color: $brand-white;
    font-size: 0.875em;
  }
}

.wizard-accordion {
  float: left;
  width: 100%;
  margin-bottom: 5em;
  .wizard-accordion-header {
    margin-bottom: 1em;
    float: left;
    width: 100%;
    font-size: 0.75em;
    font-weight: bold;
    color: $brand-slate;
  }
  .wizard-accordion-row {
    margin-bottom: 0.5em;
    width: 100%;
    .wizard-accordion-matching {
      padding: 1em;
      background: $brand-slate-lighter;
      h1 {
        float: left;
        margin: 0;
        padding: 0;
        line-height: 1.375;
      }
      .arrow-right {
        float: right;
        margin: 0.9em 0 0;
      }
    }
    .wizard-accordion-actions {
      padding: 1em;
      .status {
        float: left;
        padding: 0.75em 0;
        margin-bottom: 0 !important;
        color: $brand-color;
      }
    }

    &.status-automatched,
    &.status-matched {
      .status {
        color: $brand-green;
      }
    }
    &.status-unmatched {
      .status {
        color: $brand-red;
      }
    }
    &.status-skipped {
      .wizard-accordion-matching {
        opacity: 0.5;
      }
      .status {
        color: tint($brand-slate, 50%);
      }
    }
  }
  .wizard-accordion-list {
    float: left;
    width: 100%;
    margin-bottom: 2em;
    color: $brand-slate;
    h2 {
      float: left;
      width: 100%;
      padding: 0.25em 0 0.75em;
      margin: 0.25em 0 0 0.25em;
      border-bottom: 1px solid $brand-slate-light;
    }
  }
}

.wizard-review,
.wizard-sidebar-container {
  float: left;
  width: 100%;
  min-height: 25em;
  // height set by useStickyWizardSidebar.jsx
  margin-bottom: 4em;
  .wizard-review-sidebar,
  .wizard-sidebar {
    position: fixed;
    width: 25%;
    .sidebar-option {
      @include transition-all(0.25s);
      cursor: pointer;
      padding: 0 1em;
      .sidebar-value {
        width: 100%;
        margin-top: 1em;
        @extend .label;
        display: inline-block;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5;
        text-align: center;
        border-radius: 50%;
        color: $brand-white;
        background: tint($brand-green, 50%);
      }

      &.errors {
        .sidebar-value {
          background: $brand-red;
        }
      }
      .sidebar-title {
        float: none !important;
        width: auto !important;
        @extend .label;
        display: inline-block;
        color: $brand-slate;
        line-height: 2.5;
        font-weight: bold;
        padding: 1em;
        margin: 0;
      }
      &.active,
      &:hover {
        background: tint($brand-slate-light, 80%);
      }
    }
  }
  .wizard-sidebar {
    width: 18%;
    .wizard-sidebar-scrollable {
      padding-top: 2em;
      padding-left: 2em;
      padding-right: 2em;
      padding-bottom: 4em;
      height: 100%;
      overflow-y: auto;
      label {
        font-family: $open-sans;
        font-weight: $weight-600;
        font-size: 0.875em;
        font-weight: 600;
        display: block;
        color: $brand-slate;
        margin: 0 0 0.75em 0;
      }
    }
    &.wizard-sidebar-grade-only,
    &.wizard-sidebar-grade-only * {
      &:before,
      &:after {
        display: none;
      }
      .grade-circle {
        position: relative;
        padding: 0;
      }
    }
  }
}

.wizard-review-group {
  background: $brand-white;
  .wizard-review-object,
  .wizard-review-data {
    h2 {
      margin: 0.34em 0;
    }
    [class*="l-arrow"] {
      width: 40%;
      max-width: 2em;
      left: 30%;
      position: relative;
      top: 0.65em;
    }
    .wizard-review-changeset,
    .wizard-review-data {
      float: left;
      width: 100%;
      span {
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
        vertical-align: middle;
        margin: 0 !important;
        font-size: 1em;
        line-height: 1.25;
      }
      .review-label {
        color: $brand-slate;
      }
      .review-current,
      .review-new-error {
        display: inline-block;
        padding-right: 1em;
      }
      .revew-current {
        color: tint($brand-slate, 50%);
      }
      .review-new-error {
        color: tint($brand-slate, 30%);
      }
      .review-new {
        color: $brand-green;
        padding-right: 1em;
      }
      .review-notice,
      .review-error {
        font-size: 0.875em;
        display: inline-block;
      }
      .review-notice {
        color: tint($brand-slate, 30%);
      }
      .review-error {
        color: $brand-red;
      }
      .review-strikethrough {
        text-decoration: line-through;
        color: tint($brand-slate, 50%) !important;
      }
    }
  }

  .wizard-review-object {
    &:nth-child(even) {
      background: $brand-slate-lighter;
    }
    padding: 0.5em 0;
  }
}

.wizard-review-stretched {
  margin: -3em;
  padding: 0;
  background: none;
  .wizard-review-object {
    padding: 2em 8em;
  }
}
@media only screen and (max-width: 1200px) {
  .wizard-review-stretched {
    margin: -3em;
    padding: 0;
    .wizard-review-object {
      padding: 2em 3em;
    }
  }
}

.wizard-review,
.wizard-sidebar-container {
  .wizard-review-group {
    background: tint($brand-slate-light, 80%);
    padding: 0 1em 1em 1em;
    min-height: 350px;
    width: 100%;
    float: left;
    margin-bottom: 6em;
    .wizard-review-object {
      padding: 2em;
    }
  }
}

.wizard-review-group-stretch {
  padding: 2em;
  margin: -3em;
  background: tint($brand-slate-light, 90%);
  .wizard-review-object {
    padding: 2em 8em;
  }
}

.wizard-review-columns {
  padding: 2em !important;
  h2 {
    margin-bottom: 0.5em;
    width: 50%;
    float: left;
    padding: 0.25em;
    line-height: 1.5;
    margin: 0;
  }
}

.wizard-review-summary {
  .wizard-form-container {
    .row {
      background: none !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  .wizard-review-columns {
    h2 {
      width: 33.32%;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .wizard-review-columns {
    h2 {
      width: 25%;
    }
  }
}

.wizard-review-summary {
  .row {
    margin-bottom: 2em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  h1 {
    text-align: center;
    color: $brand-slate;
    margin: 0;
  }
  p {
    margin: 0.35em 0;
    color: tint($brand-slate, 30%);
  }
}

@media only screen and (max-height: 992px) {
  .wizard-sidebar:not(.wizard-sidebar-grade-only) {
    &:after {
      display: none;
    }
    &.wizard-sidebar-has-grade {
      .wizard-sidebar-scrollable {
        padding-bottom: 4em !important;
      }
    }
    .grade-label {
      max-width: 80%;
    }

    .grade-circle {
      width: 100%;
      background: $brand-slate !important;
      position: absolute;
      bottom: -1px;
      left: 0;
      padding: 1em 2em !important;
      z-index: 3;
      margin: 0 !important;
      &:before {
        background: linear-gradient(
          to bottom,
          rgba($brand-slate-light, 0) 0%,
          rgba($brand-slate-light, 1) 100%
        ) !important;
        top: -2em !important;
        left: 0;
        width: 100%;
        height: 2em !important;
        content: "";
        z-index: 2;
        position: absolute;
      }
      .grade-value {
        font-family: $proxima-soft;
        font-weight: $weight-200;
        font-size: 1em !important;
        font-weight: bold !important;
        color: $brand-white !important;
        border: none !important;
        border-radius: 0 !important;
        width: auto !important;
        height: auto !important;
        line-height: 1.5 !important;
        float: right;
        margin: 0 !important;
        padding: 0 !important;
        sup {
          font-family: $proxima-soft;
          font-weight: $weight-200;
          top: 0;
          font-size: 1em;
        }
      }
      .grade-label {
        font-family: $proxima-soft;
        font-weight: $weight-200;
        margin: 0 !important;
        padding: 0 !important;
        font-size: 1em !important;
        color: $brand-white !important;
        line-height: 1.5 !important;
        float: left;
      }
    }
  }
  .wizard-header {
    padding: 1em 0 !important;
  }
  .wizard-timeline-container {
    padding-top: 2em !important;
  }
  .wizard-title-section {
    padding: 2em 0 1em !important;
  }
  .wizard-footer {
    padding: 1em 2em !important;
  }
}

// THIS IS DUPLICATE CODE^^^, CAN BE REMOVED/REPLACED IF THERE's A WAY TO COMBINE A CLASS AND BREAKPOINT RULE IN ONE WRAPPER.
.wizard-sidebar-shrink {
  &:after {
    display: none;
  }
  .wizard-sidebar-scrollable {
    padding-bottom: 4em !important;
  }
  .grade-label {
    max-width: 80%;
  }

  .grade-circle {
    width: 100%;
    background: $brand-slate !important;
    position: absolute;
    bottom: -1px;
    left: 0;
    padding: 1em 2em !important;
    z-index: 3;
    margin: 0 !important;
    &:before {
      background: linear-gradient(
        to bottom,
        rgba($brand-slate-lighter, 0) 0%,
        rgba($brand-slate-lighter, 1) 100%
      ) !important;
      top: -2em !important;
      left: 0;
      width: 100%;
      height: 2em !important;
      content: "";
      z-index: 2;
      position: absolute;
    }
    .grade-value {
      font-family: $proxima-soft;
      font-weight: $weight-200;
      font-size: 1em !important;
      font-weight: bold !important;
      color: $brand-white !important;
      border: none !important;
      border-radius: 0 !important;
      width: auto !important;
      height: auto !important;
      line-height: 1.5 !important;
      float: right;
      margin: 0 !important;
      padding: 0 !important;
      sup {
        font-family: $proxima-soft;
        font-weight: $weight-200;
        top: 0;
        font-size: 1em;
      }
    }
    .grade-label {
      font-family: $proxima-soft;
      font-weight: $weight-200;
      margin: 0 !important;
      padding: 0 !important;
      font-size: 1em !important;
      color: $brand-white !important;
      line-height: 1.5 !important;
      float: left;
    }
  }
}

.wizard-header {
  padding: 1em 0 !important;
}
.wizard-timeline-container {
  padding-top: 2em !important;
}
.wizard-title-section {
  padding: 2em 0 1em !important;
}
.wizard-footer {
  padding: 1em 2em !important;
}

@media only screen and (max-width: 992px) {
  .wizard-sidebar .grade-circle {
    font-size: 0.875em;
  }
  .wizard-form-container {
    padding: 1em !important;
  }
  .wizard-sidebar-scrollable {
    padding-top: 1em !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .wizard-sidebar-buttons {
    button {
      padding: 0.5em;
    }
  }
}

table#match-benchmark-positions {
  margin-bottom: 6em;
}

table#match-benchmark-positions tbody tr.matched .position-title,
table#match-benchmark-positions tbody tr.matched td {
  color: rgba(59, 65, 80, 0.5) !important;
}

table#match-benchmark-positions tbody tr.unmatched.skipped td {
  color: #576172;
  font-style: italic;
}

table#match-benchmark-positions tbody tr td .form-group,
table#match-benchmark-positions tbody tr td .form-group.active {
  margin: 0 !important;
  padding: 0 !important;
}

table#match-benchmark-positions tbody tr td select.benchmark-positions,
table#match-benchmark-positions tbody tr td select.department-title {
  width: 95%;
  margin: 0;
}

table#match-benchmark-positions tbody tr td:first-child {
  vertical-align: middle;
  text-align: left;
}

table#match-benchmark-positions tbody tr td.skip-actions {
  vertical-align: middle;
  text-align: center;
  width: 100px;
}

table#match-benchmark-positions tbody tr td.skip-actions .skip-matching {
  color: #e4252a !important;
  font-size: 20px;
}

table#match-benchmark-positions tbody tr td.skip-actions span.reset-matching {
  color: rgba(87, 97, 114, 0.5);
}

table#match-benchmark-positions tbody tr.active td {
  padding: 20px 0;
  background-color: #fff !important;
}

table#match-benchmark-positions tbody tr.active .position-title {
  font-size: 22px;
  color: #576172;
}

table#match-benchmark-positions tbody tr.active .skip-matching {
  color: #3b4150;
  text-decoration: none !important;
  cursor: pointer;
}

table#match-benchmark-positions thead th:last-child {
  vertical-align: bottom;
}

table#match-benchmark-positions tbody tr {
  border-top: 1px solid #dfe4ea;
}

table#match-benchmark-positions tbody tr td {
  border-top: 1px solid #dfe4ea;
  padding: 15px 0;
  text-align: center;
}

table#match-benchmark-positions tbody tr td .position-title {
  font-size: 14px;
  color: #576172;
}

table#match-benchmark-positions {
  margin-top: 60px;
}

table#match-benchmark-positions thead th {
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 15px;
  text-align: center;
}

table#match-benchmark-positions thead th .step {
  font-size: 24px;
  font-weight: 100;
  display: block;
  color: #e4252a;
  margin-bottom: 5px;
}

table#match-benchmark-positions thead th .what-to-select {
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  font-size: 18px;
  margin-top: 5px;
  color: #050a14;
  letter-spacing: 0.05em;
}

table#match-benchmark-positions thead th:first-child {
  vertical-align: bottom;
  text-align: left;
  color: #050a14;
}
