// Shepherd Overwrites

.shepherd-element {
  border-radius: 1.5em;
  box-shadow: none;
  background: none;
  margin: 0 0 1.5em !important;
}

.shepherd-element.shepherd-open,
.shepherd-target.shepherd-enabled {
  @include BoxShadowHelper(3);
  border: none !important;
}

.shepherd-content {
  overflow: hidden;
  border-radius: 1em;
  @include BoxShadowHelper(3);
  background: none;
}

.shepherd-header {
  padding: 2em;
  border-radius: 1em 1em 0 0;
  background: $brand-slate-dark !important;
  h3 {
    font-size: 1em;
    color: $brand-white !important;
  }
}

.shepherd-text {
  background: $brand-white;
  color: $brand-slate-dark;
  font-size: 1em;
  padding: 2em;
}

.shepherd-footer {
  padding: 1em 2em;
  margin-top: 0em;
  border-radius: 0 0 1em 1em;
  background: $brand-white;
  border: none;
}

.shepherd-arrow {
  z-index: 5;
  position: relative;
  border-color: $brand-slate-light;
}

.shepherd-button {
  background: $brand-red !important;
  color: $brand-white;
  text-decoration: none;
  &:hover {
    background: lighten($brand-red, 10%) !important;
  }
}
