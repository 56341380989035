.modal-open {
  overflow: hidden !important;
  #main-body {
    filter: blur(3px);
  }
  .modal {
    display: block !important;
  }
}

.modal {
  z-index: 999;
  position: relative;
  .modal-overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($brand-slate, 0.7);
    z-index: 1;
    display: flex;
    overflow-y: auto;

    &.modal-overlay-opaque {
      background-color: $brand-slate;
    }
  }

  .modal-container {
    display: flex;
    width: 100%;
    max-height: 100vh;
    min-width: 20em;
    max-width: 60em;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    margin: 0 auto;
    outline: none;
    box-shadow: none;

    .m-header,
    .m-body,
    .m-footer {
      width: 100%;
      flex-grow: 0;
      position: relative;
    }

    .m-header {
      padding-bottom: 0.25em;
      border-bottom: 1px solid $brand-slate-light;
      background: $brand-white;
      padding: 1em 2em;

      h1,
      h2 {
        margin: 0.25rem 0;
        float: left;
        width: 100%;
        padding-right: 2rem;
      }

      strong {
        color: $brand-slate;
      }

      .m-close {
        font-size: 1.35em;
        line-height: 1;
        cursor: pointer;
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        z-index: 100;
        padding: 1em 1.5em;
        color: $brand-color;
        @include transition-all(0.25s);

        &:hover {
          color: tint($brand-color, 15%);
        }
      }

      &:after {
        z-index: 99;
        width: 100%;
        height: 31px;
        position: absolute;
        bottom: -32px;
        left: 0;
        background: linear-gradient(
          to top,
          rgba($brand-white, 0) 0%,
          $brand-white 100%
        );
        content: "";
        pointer-events: none;
      }
    }

    .m-body {
      padding: 2em;
      flex-grow: 1;
      overflow-y: visible;
      background: $brand-white;

      table tr,
      table tr:hover {
        background: none;
        box-shadow: none;
      }

      &.m-scroll {
        overflow-y: auto !important;
      }

      .select__menu {
        max-height: 16em;
      }

      .row {
        padding-top: 1em;
        padding-bottom: 1em;
      }

      p {
        color: $brand-slate;
      }

      a {
        color: $brand-link-blue;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: tint($brand-link-blue, 15%);
        }

        &:active {
          color: darken($brand-link-blue, 15%);
        }

        span {
          color: $brand-slate;
        }
      }

      .checkbox {
        float: none;
        display: inline-block;

        label {
          padding: 0.25em 0 0.25em 1.5em;
        }
      }

      .checkbox.horizontal {
        input[type="checkbox"] {
          display: inline;
          opacity: initial;
          position: initial;
          margin-left: 6px;
        }

        label {
          display: inline;
        }
      }

      .row:nth-child(even) {
        background: rgba($brand-slate-lighter, 0.2);
      }

      .table-section {
        &:before,
        &:after {
          display: none;
        }

        .section-content {
          margin: 0;
          padding: 0;

          table {
            width: 100%;
            min-width: auto;

            tr {
              .field {
                margin: 0;
              }
              tbody {
                &:hover {
                  background: rgba($brand-black, 0.02) !important;
                }
              }
            }
          }
        }
      }

      .notice-message {
        border: 1px solid $draft-color;
        padding: 2em 2em 2em 5em;
        margin-bottom: 2em;
        max-width: 56em;
        width: 100%;
        z-index: 2;
        position: relative;

        i {
          color: $draft-color;
          z-index: 1;
          font-size: 1em;
          top: 50%;
          left: 0;
          position: absolute;
          line-height: 2;
          height: 2em;
          width: 5em;
          transform: translateY(-50%);
          text-align: center;
          padding-top: 2px;
        }

        h2 {
          margin-bottom: 0em;
        }

        p {
          color: $draft-color;
          font-weight: 600;
          margin-bottom: 0em;
        }
      }

      .small-notice {
        color: tint($brand-slate, 30%);
        font-weight: 600;
        padding: 0.75em 0;
        display: inline-block;
      }
    }

    .m-footer {
      padding: 1em 2em;

      &.empty {
        padding: 0;
      }

      background: $brand-slate;

      &.m-wide {
        max-width: 95vw;
      }
      .file-chosen {
        color: $brand-white;
        float: left;
        margin: 0.6em 0;
      }

      .choose-file {
        float: left;
        position: relative;

        #choose-file {
          display: none;
        }

        button {
          min-width: 110px;
          border-color: transparent;
          margin-right: 1em;
        }

        small {
          color: $brand-white;
        }

        i {
          margin-left: 0;
        }
      }
    }

    button {
      &.submit,
      &.primary-action {
        background: $brand-color;
        border: 1px solid $brand-color;
        color: $brand-white;
        @include transition-all(0.25s);
        padding: 0.75em 2em;
        margin: 0 0.5em;
        box-shadow: 0 0 0 rgba($brand-black, 0);
        border-radius: 0 !important;

        &:hover {
          box-shadow: 0 0.125em 0.25em rgba($brand-black, 0.2);
          transform: translateY(-2px);
          background: tint($brand-color, 15%);
          border-color: tint($brand-color, 15%);
        }

        &:active {
          box-shadow: 0 0 0.25em rgba($brand-black, 0.1);
          transform: translateY(1px);
          background: darken($brand-color, 15%);
          border-color: darken($brand-color, 15%);
        }
      }

      &.secondary,
      &.secondary-action {
        background: none;
        color: $brand-white;
        border: 1px solid $brand-white;
        @include transition-all(0.25s);
        padding: 0.75em 2em;
        margin: 0 0.5em;
        box-shadow: 0 0 0 rgba($brand-black, 0);
        border-radius: 0 !important;

        &:hover {
          background: $brand-white;
          border: 1px solid $brand-white;
          background: darken($brand-white, 15%);
        }

        &:active {
          box-shadow: 0 0 0.25em rgba($brand-black, 0.1);
          transform: translateY(1px);
          border-color: rgba($brand-white, 0.9);
          background: rgba($brand-white, 0.9);
        }
      }

      &:disabled,
      &.working {
        cursor: not-allowed;
        box-shadow: none;
        color: $brand-white;
        background: tint($brand-slate, 50%);
        border: 1px solid tint($brand-slate, 50%);
        @include transition-all(0.25s);
        padding: 0.75em 2em;
        margin: 0 0.5em;
        box-shadow: 0 0 0 rgba($brand-black, 0);
        border-radius: 0 !important;

        &:hover {
          box-shadow: none;
          transform: translateY(0);
        }
      }

      &.working {
        .ball-pulse {
          transform: translateY(2px);

          div {
            background: $brand-slate;
          }
        }
      }

      &.reset {
        padding: 1em 0;
        background: none;
        border: none;
        box-shadow: none;
        color: $brand-slate-light;
        @include transition-all(0.25s);
        padding: 0.75em 2em;
        margin: 0 0.5em;
        box-shadow: 0 0 0 rgba($brand-black, 0);
        border-radius: 0 !important;
        &:hover {
          color: $brand-white;
          transform: translate(0);
          background: transparent;
        }

        &:active {
          background: rgba($brand-black, 0.1);
        }

        i {
          padding-right: 0.5em;
        }
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .modal-is-promotional {
    margin-top: 2em;
    .m-promo-close {
      cursor: pointer;
      font-size: 1em;
      line-height: 1;
      letter-spacing: 1px;
      position: absolute;
      top: -2em;
      right: 0;
      z-index: 100;
      padding: 0.5em 0;
      color: $brand-white;
      transition: all 0.25s;
    }

    .m-promo-image {
      min-height: 2em;
      img {
        margin: -2em 0 0 0;
      }
    }
    .m-header {
      background: none !important;
      border-bottom: none !important;
      &:after {
        display: none;
      }
    }
    .m-body {
      padding: 2em 2em 3em;
    }
    .m-footer {
      padding-top: 1em;
      background: $brand-color !important;

      button.submit,
      button.primary-action {
        background: $brand-white;
        border-color: $brand-white;
        color: $brand-color;

        &:hover {
          background: tint($brand-white, 15%);
          border-color: tint($brand-white, 15%);
        }

        &:active {
          background: darken($brand-white, 15%);
          border-color: darken($brand-white, 15%);
        }
      }
    }
  }

  .for-notes {
    .m-header {
      border-bottom: 1px solid $notes-color;
      background: $notes-color;
      color: $brand-slate;

      .m-close {
        color: $brand-slate;

        &:hover {
          color: darken($brand-slate, 15%);
        }
      }
    }

    button.submit,
    button.primary-action {
      background: $notes-color;
      border-color: $notes-color;
      color: $brand-slate;

      &:hover {
        background: tint($notes-color, 15%);
        border-color: tint($notes-color, 15%);
      }

      &:active {
        background: darken($notes-color, 15%);
        border-color: darken($notes-color, 15%);
      }
    }
  }

  .for-draft {
    .m-header {
      border-bottom: 1px solid $draft-color;
      background: $draft-color;
      color: $brand-white;

      .m-close {
        color: $brand-white;

        &:hover {
          color: darken($brand-white, 15%);
        }
      }
    }

    button.submit,
    button.primary-action {
      background: $draft-color;
      border-color: $draft-color;
      color: $brand-white;

      &:hover {
        background: tint($draft-color, 15%);
        border-color: tint($draft-color, 15%);
      }

      &:active {
        background: darken($draft-color, 15%);
        border-color: darken($draft-color, 15%);
      }
    }
  }

  @media screen and (max-height: 20em) {
    .m-container {
      align-self: auto;
      height: auto;
      max-height: auto;
      min-height: fit-content;

      .m-body {
        height: auto;
        overflow: auto;
      }
    }
  }
}

#logout-modal-message {
  text-align: center;
  font-size: 1em;
}

// Processing modal
.m-body-for-processing {
  text-align: center;
  min-height: auto;
  padding: 7.5em 0 0;

  h1,
  small {
    display: inline-block;
    width: 100%;
  }

  small {
    opacity: 0.5;
  }

  .processing-circle {
    width: 4em;
    height: 4em;
    background: $brand-white;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 2em;
    position: relative;
    z-index: 1;

    .processing-loadbar {
      overflow: hidden;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4.5em;
      height: 4.5em;
      z-index: -1;
      content: "";
      animation: processingSpinOuter 1.93s linear infinite;
      transform-origin: center;

      &:before {
        background: $brand-color;
        position: absolute;
        top: 0em;
        left: 0em;
        width: 2.25em;
        height: 2.25em;
        z-index: -1;
        content: "";
        animation: processingSpinInner 1.18s cubic-bezier(0.5, 0.2, 0.5, 0.8)
          infinite;
        transform-origin: bottom right;
      }

      &:after {
        background: rgba($brand-black, 0.3);
        position: absolute;
        top: 0em;
        left: 0em;
        width: 4.5em;
        height: 2.25em;
        z-index: -2;
        content: "";
        animation: processingSpinInner 2.16s cubic-bezier(0.5, 0.2, 0.5, 0.8)
          infinite;
        transform-origin: bottom center;
      }
    }

    .processing-icon {
      background: $brand-white;
      z-index: 1;
      width: 4em;
      height: 4em;
      border-radius: 50%;
      overflow: hidden;

      .icon {
        background-image: get-icon("wizard", $brand-slate);
        width: 3em;
        height: 3em;
        margin: 1em 0.25em;
      }
    }
  }
}

@keyframes processingSpinOuter {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes processingSpinInner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Searchable Modal List
.modal-searchable {
  &-input {
    display: inline-block;
    position: relative;
    width: 100%;
    // margin-bottom: 2em;

    &-icon {
      @include transition-all(0.25s);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $brand-slate;
      font-family: "Font Awesome 5 Pro";
      width: 2em;
      padding: 0 0 0 0.5em;
      text-align: center;
    }

    input {
      width: 100%;
      padding-left: 2em;
    }
  }

  &-list {
    &-item {
      position: relative;

      label {
        background: rgba($brand-white, 0);
        display: block;
        width: 100%;
        padding: 0.5em 1em;
        margin: 0;
        font-size: 1.25em;
        font-weight: 300;
        cursor: pointer;
        transition: ease all 0.25s;

        &:hover {
          background: rgba($brand-slate, 0.3);
        }

        &:active {
          background: rgba($brand-color, 0.2);
        }
      }

      input {
        display: none;
        margin-left: -200vw;

        &:checked ~ label {
          background: $brand-color;
          color: $brand-white;
        }
      }
    }
  }
}

.modal-steps {
  text-align: center;
  color: $brand-white;
  position: absolute;
  left: 50%;
  border-radius: 5em;
  padding: 0.5em;
  margin-top: -4.5em;
  font-size: 1em;
  transform: translateX(-50%);

  i {
    padding: 0 0.5em;
    line-height: 1;
  }

  span {
    width: 0.75em;
    height: 0.75em;
    display: inline-block;
    margin: 0.5em;
    border-radius: 50%;
    background: $brand-slate-light;

    &.active {
      background: $brand-color;
    }
  }
}
